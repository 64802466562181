<template>
  <div class="membership">
    <div class="container-fluid membership-banner">
      <div class="container">
        <div class="row align-items-center justify-content-between">
          <div class="col-md-5">
            <div class="content-box">
              <h2>
                Contact Us
              </h2>
            </div>
          </div>
          <div class="col-md-5">
            <div class="image-wrap">
              <img src="../assets/webinar-hero.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pricing-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <form @submit.prevent="contactUs">
              <div class="row">
                <div class="col-md-12 mb-3">
                  <label>Name</label>
                  <input type="text" v-model="name" class="form-control" />
                  <div
                    class="input-errors"
                    v-for="(error, errorIndex) of v$.name.$errors"
                    :key="errorIndex"
                  >
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                  <div v-if="errors && errors.name" class="error-msg">
                    {{ errors.name[0] }}
                  </div>
                </div>
                <div class="col-md-6  mb-3">
                  <div class="form-group">
                    <label>Email</label>
                    <input type="text" v-model="email" class="form-control" />
                    <div
                      class="input-errors"
                      v-for="(error, errorIndex) of v$.email.$errors"
                      :key="errorIndex"
                    >
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                    <div v-if="errors && errors.email" class="error-msg">
                      {{ errors.email[0] }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6  mb-3">
                  <label>Phone</label>
                  <input type="text" v-model="phone" class="form-control" />
                  <div
                    class="input-errors"
                    v-for="(error, errorIndex) of v$.phone.$errors"
                    :key="errorIndex"
                  >
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                  <div v-if="errors && errors.phone" class="error-msg">
                    {{ errors.phone[0] }}
                  </div>
                </div>
                <div class="col-md-12  mb-3">
                  <label>Message</label>
                  <textarea class="form-control" v-model="message"></textarea>
                  <div
                    class="input-errors"
                    v-for="(error, errorIndex) of v$.message.$errors"
                    :key="errorIndex"
                  >
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                  <div v-if="errors && errors.message" class="error-msg">
                    {{ errors.message[0] }}
                  </div>
                </div>
                <div class="col-md-12  mb-3">
                  <button
                    type="submit"
                    class="btn cta-primary"
                    :disabled="isSubmitting == true"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-6">
            <div class="address-box">
              <h5>Find us at</h5>
              <div class="row">
                <div class="col-md-2">
                  <img src="../assets/KU-logo.png" alt="kids-unlimited" />
                </div>
                <div class="col-md-10">
                  <p>
                    {{ companyDetails.address }}
                  </p>
                  <div class="Phone">
                    <i class="fas fa-headset"></i>
                    <a href="#">{{ companyDetails.primary_phone }}</a>
                  </div>
                  <div
                    v-if="companyDetails.secondary_phone != NULL"
                    class="Phone"
                  >
                    <i class="fas fa-headset"></i>
                    <a href="#">{{ companyDetails.secondary_phone }}</a>
                  </div>
                  <div class="mail">
                    <i class="far fa-envelope"></i>
                    <a href="#">{{ companyDetails.primary_email }}</a>
                  </div>
                  <div
                    v-if="companyDetails.secondary_email != NULL"
                    class="mail"
                  >
                    <i class="far fa-envelope"></i>
                    <a href="#">{{ companyDetails.secondary_email }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, helpers, maxLength } from "@vuelidate/validators";
import HomeService from "@/services/HomeService.js";
import errorLog from "@/errorLog";
export default {
  name: "contact us",
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  validations() {
    return {
      name: {
        required: helpers.withMessage("Please enter  name", required)
      },
      email: {
        required: helpers.withMessage("Please enter email", required)
      },
      phone: {
        required: helpers.withMessage("Please enter phone number", required)
      },
      message: {
        required: helpers.withMessage("Please enter message", required),
        maxLength: maxLength(2000)
      }
    };
  },
  created() {
    this.getCompanyDetails();
  },
  data() {
    return {
      name: "",
      phone: "",
      email: "",
      message: "",
      errors: {},
      isSubmitting: false,
      companyDetails: []
    };
  },
  methods: {
    contactUs: function() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      this.isSubmitting = true;
      const data = {
        name: this.name,
        email: this.email,
        phone: this.phone,
        message: this.message
      };
      HomeService.saveContact(data)
        .then(result => {
          this.isSubmitting = false;
          if (result.data.status == "success") {
            this.$toast.success(result.data.message);
            this.name = "";
            this.email = "";
            this.phone = "";
            this.message = "";
            this.v$.$reset();
          } else if (result.data.status == "validation_error") {
            this.errors = result.data.message || {};
          }
        })
        .catch(error => {
          errorLog.log(error);
        });
    },
    getCompanyDetails: function() {
      HomeService.getCompanyDetails()
        .then(result => {
          this.companyDetails = result.data;
        })
        .catch(error => {
          errorLog.log(error);
        });
    }
  }
};
</script>

<style lang="scss">
@import "@/style/membership.scss";
</style>
